import { render, staticRenderFns } from "./starDialog.vue?vue&type=template&id=25938694&scoped=true"
import script from "./starDialog.vue?vue&type=script&lang=js"
export * from "./starDialog.vue?vue&type=script&lang=js"
import style0 from "./starDialog.vue?vue&type=style&index=0&id=25938694&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25938694",
  null
  
)

export default component.exports