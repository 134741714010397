<template>
  <div class="main-header">
    <div class="main-header-left">
      <div class="main-header-language">
        <!-- <img
          src="@/assets/img/earth.png"
          @click="handleLang"
          alt=""
          srcset=""
        /> -->
      </div>
    </div>
    <div class="main-header-right">
      <div class="main-header-my" @click="handleClick">
        <svg
          t="1729133807945"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5125"
          width="20"
          height="20"
        >
          <path
            d="M535.272727 69.818182c-257.070545 0-465.454545 208.384-465.454545 465.454545 0 257.070545 208.384 465.454545 465.454545 465.454545 257.070545 0 465.454545-208.384 465.454545-465.454545C1000.727273 278.202182 792.343273 69.818182 535.272727 69.818182zM254.184727 845.265455C298.030545 732.253091 406.760727 651.636364 535.272727 651.636364c128.512 0 237.242182 80.616727 281.088 193.582545-74.379636 67.490909-172.776727 108.962909-281.088 108.962909C426.961455 954.181818 328.564364 912.709818 254.184727 845.265455zM395.636364 465.454545c0-77.125818 62.510545-139.636364 139.636364-139.636364 77.125818 0 139.636364 62.510545 139.636364 139.636364s-62.510545 139.636364-139.636364 139.636364C458.146909 605.090909 395.636364 542.580364 395.636364 465.454545zM851.502545 809.472c-41.146182-89.786182-118.365091-159.278545-213.876364-188.695273C688.081455 587.496727 721.454545 530.432 721.454545 465.454545c0-102.818909-83.362909-186.181818-186.181818-186.181818-102.818909 0-186.181818 83.362909-186.181818 186.181818 0 64.977455 33.373091 122.042182 83.874909 155.368727-95.511273 29.370182-172.730182 98.862545-213.876364 188.695273C155.229091 735.976727 116.363636 640.279273 116.363636 535.272727c0-231.377455 187.531636-418.909091 418.909091-418.909091 231.377455 0 418.909091 187.531636 418.909091 418.909091C954.181818 640.279273 915.269818 735.976727 851.502545 809.472z"
            fill="#1188ff"
            p-id="5126"
          ></path>
        </svg>
        <span>{{ $t("Header.myTicket") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleLang() {
      this.$parent.handleLang();
    },
    handleClick() {
      this.$router.push("/myTicket");
    },
  },
};
</script>

<style scoped lang="scss">
.main-header {
  height: 40px;
  width: 600px;
  background-color: #fff;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

.main-header-left {
  display: flex;
  justify-content: space-evenly;
  width: 80px;
}
.main-header-right {
  display: flex;
  justify-content: space-evenly;
  min-width: 100px;
  margin-right: 10px;
}

.main-header-language {
  width: 24px;
  height: 24px;
}
.main-header-language img {
  width: 24px;
  cursor: pointer;
}

.main-header-my {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(17, 136, 255);
  font-size: 14px;
  cursor: pointer;
  min-width: 90px;
}
@media screen and (max-width: 1024px) {
  .main-header {
    width: 100%;
  }
}
</style>
