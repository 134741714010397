var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('NavBar',{attrs:{"title":_vm.$t('detail.title'),"routeUrl":'/question?type=' + _vm.info.type}}),_c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.info.problem))]),_c('div',{staticClass:"detail-content",domProps:{"innerHTML":_vm._s(_vm.info.answer)}}),_c('div',{staticClass:"detail-grade"},[_c('div',[_vm._v(_vm._s(_vm.$t('detail.isAppraise')))]),_c('div',{staticClass:"detail-grade-list"},[(
            !_vm.info.is_appraise ||
            (_vm.info.is_appraise && _vm.info.appraise_type === 0)
          )?_c('div',{staticClass:"detail-grade-item",on:{"click":function($event){return _vm.handleClick(0)}}},[_c('img',{class:_vm.info.is_appraise ? 'detail-grade-img' : '',attrs:{"src":require("../../../assets/img/good.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('detail.appraise')))])]):_vm._e(),(
            !_vm.info.is_appraise ||
            (_vm.info.is_appraise && _vm.info.appraise_type === 1)
          )?_c('div',{staticClass:"detail-grade-item",on:{"click":function($event){return _vm.handleClick(1)}}},[_c('img',{class:_vm.info.is_appraise ? 'detail-grade-img' : '',attrs:{"src":require("../../../assets/img/bad.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('detail.noAppraise')))])]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }