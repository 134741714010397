<template>
  <div class="article">
    <!-- <Header /> -->
    <NavBar :title="$t('Main.article')" routeUrl="/" />
    <div class="article-body">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in articleData"
          :key="index"
          :title="item.title"
          :to="'/articleDetail?id=' + item.id"
          is-link
        />
      </van-cell-group>
      <Kefu />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import NavBar from "@/components/NavBar.vue";
import Kefu from "@/components/kefu";
export default {
  name: "question",
  mixins: [],
  components: { Header, NavBar, Kefu },
  props: {},
  data() {
    return {
      articleData: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.post("/api/v2/articleList", { type: 2 }).then((res) => {
        if (res.code === 200) {
          this.articleData = res.data.list;
          console.log(this.articleData);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.article-body {
  margin-top: 45px;
}
.van-cell-group__title {
  text-align: left;
}
</style>
