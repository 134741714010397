<template>
  <div class="question">
    <!-- <Header /> -->
    <NavBar :title="$t('Main.normalQuestion')" routeUrl="/" />
    <div class="qusetion-body">
      <van-tabs
        v-model="active"
        @change="handleChange"
        animated
        swipe-threshold="2"
      >
        <van-tab
          v-for="(item, index) in listRemark"
          :key="index"
          :title="item.name"
        >
          <van-cell
            v-if="active == 2"
            :title="$t('problem.chooseGame')"
            arrow-direction="down"
            is-link
            :value="game.name"
            @click="handleSelect"
          />
          <div style="margin-top: 10px"></div>
          <div style="margin: 0 10px">
            <el-input
              v-model="value"
              :placeholder="$t('problem.problemName')"
              @input="handleInput"
              clearable
            ></el-input>
          </div>
          <div style="margin-top: 10px"></div>
          <van-cell-group>
            <van-cell
              v-for="(i, ind) in listData[item.id]"
              :key="ind"
              is-link
              @click="handleClick(i.id)"
            >
              <template #title>
                <van-tag round size="medium" type="primary" v-if="i.is_new"
                  >NEW</van-tag
                >
                <van-tag round size="medium" type="danger" v-if="i.is_hot"
                  >HOT</van-tag
                >
                <span class="custom-title">
                  {{ i.problem }}
                </span>
              </template>
            </van-cell>
          </van-cell-group>
        </van-tab>
      </van-tabs>
      <Kefu />
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import NavBar from "@/components/NavBar.vue";
import Kefu from "@/components/kefu";
export default {
  name: "question",
  mixins: [],
  components: { Header, NavBar, Kefu },
  props: {},
  data() {
    return {
      active: 3,
      listData: {},
      token: localStorage.getItem("token"),
      activeGameList: [], //活动问题列表
      game: {
        id: "",
        name: "",
      },
      show: false,
      actions: [],
      value: "",
      listItem: {}, //模块备份
      activeRemark: 3,
    };
  },
  computed: {
    list() {
      return [
        {
          name: this.$t("Main.problem1"),
          id: 1,
        },
        {
          name: this.$t("Main.problem2"),
          id: 2,
        },
        {
          name: this.$t("Main.problem3"),
          id: 3,
        },
        {
          name: this.$t("Main.problem4"),
          id: 4,
        },
        {
          name: this.$t("Main.problem5"),
          id: 5,
        },
        {
          name: this.$t("Main.problem8"),
          id: 6,
        },
      ];
    },
    listRemark: {
      get() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
      set() {
        return this.list.filter((item) => {
          return item.id && this.listData[item.id];
        });
      },
    },
  },
  watch: {},
  created() {},
  mounted() {
    let type = {
      1: 1,
      2: 2,
      3: 3,
      6: 4,
    };
    this.active = this.$route.query.type
      ? type[Number(this.$route.query.type)] - 1
      : 0;
    this.getData();
  },
  methods: {
    handleClick(e) {
      this.$router.push({ path: "/detail", query: { d: e } });
    },
    // tab 切换
    handleChange(e) {
      this.value = "";
      this.listData = JSON.parse(JSON.stringify(this.listItem));
    },
    //输入框
    handleInput() {
      let type = {
        0: 1,
        1: 2,
        2: 3,
        3: 6,
      };
      this.activeRemark = type[this.active];
      this.listData[this.activeRemark] = [];
      this.listItem[this.activeRemark].forEach((item, index) => {
        let upperFirst = item.problem.toUpperCase();
        let upperLast = this.value.toUpperCase();
        if (upperFirst.includes(upperLast)) {
          this.listData[this.activeRemark].push(item);
        }
      });
      if (this.value == "") {
        this.listData[this.activeRemark] = this.listItem[this.activeRemark];
      }
    },
    handleSelect() {
      this.show = true;
    },
    onSelect(item) {
      this.show = false;
      this.game = item;
      const filteredData = this.activeGameList.filter(
        (i) => i.game_id === this.game.id
      );
      this.listData[3] = JSON.parse(JSON.stringify(filteredData));
      this.listItem[3] = JSON.parse(JSON.stringify(filteredData));
      this.value = "";
    },
    getData() {
      let params = {
        type: this.active + 1,
      };
      this.$http.post("/api/v2/getProblemList", params).then((res) => {
        if (res.code === 200) {
          this.listData = JSON.parse(JSON.stringify(res.data.list));
          this.listItem = JSON.parse(JSON.stringify(res.data.list));
          // 过滤 list，只保留在 listData 中存在的 id
          const filteredCategories = this.list.filter((category) => {
            return category.id && res.data.list[category.id];
          });
          this.listRemark = filteredCategories;
          this.getServerAndRoleInfo();
        }
      });
    },
    //获取游戏区服角色接口
    getServerAndRoleInfo() {
      let params = {
        token: this.token,
      };
      this.$http.post("/api/v2/gameList", params).then((res) => {
        if (res.code == 200) {
          this.actions = res.data.map((action) => ({
            id: action.game_id,
            name: action.game_name,
          }));
          this.game = this.actions[0];
          this.activeGameList = this.listData[3];
          const filteredData = this.activeGameList.filter(
            (item) => item.game_id === this.game.id
          );
          this.listData[3] = JSON.parse(JSON.stringify(filteredData));
          this.listItem[3] = JSON.parse(JSON.stringify(filteredData));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qusetion-body {
  margin-top: 45px;
}
.van-cell-group__title {
  text-align: left;
}
.van-tag--round {
  margin-right: 5px;
}
</style>
