<template>
  <div class="detail">
    <NavBar
      :title="$t('detail.title')"
      :routeUrl="'/question?type=' + info.type"
    />
    <div class="detail-page">
      <div class="detail-title">{{ info.problem }}</div>
      <div class="detail-content" v-html="info.answer"></div>
      <div class="detail-grade">
        <div>{{ $t('detail.isAppraise') }}</div>
        <div class="detail-grade-list">
          <div
            class="detail-grade-item"
            @click="handleClick(0)"
            v-if="
              !info.is_appraise ||
              (info.is_appraise && info.appraise_type === 0)
            "
          >
            <img
              :class="info.is_appraise ? 'detail-grade-img' : ''"
              src="../../../assets/img/good.png"
            />
            <span>{{ $t('detail.appraise') }}</span>
          </div>
          <div
            class="detail-grade-item"
            @click="handleClick(1)"
            v-if="
              !info.is_appraise ||
              (info.is_appraise && info.appraise_type === 1)
            "
          >
            <img
              :class="info.is_appraise ? 'detail-grade-img' : ''"
              src="../../../assets/img/bad.png"
            />
            <span>{{ $t('detail.noAppraise') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "detail",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      problem_id: "",
      info: "",
      token: localStorage.getItem("token"),
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.problem_id = this.$route.query.d ? this.$route.query.d : "";
      let params = {
        problem_id: this.problem_id,
        token: this.token,
      };
      this.$http.post("/api/v2/getProblem", params).then((res) => {
        if (res.code === 200) {
          this.info = res.data.info;
        }
      });
    },
    handleClick(type) {
      if (this.info.is_appraise) {
        return
      }
      let params = {
        problem_id: this.problem_id,
        token: this.token,
        type,
      };
      this.$http.post("/api/v2/problemAppraise", params).then((res) => {
        if (res.code === 200) {
          this.getData();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  height: 100%;
}
.detail-page {
  height: auto;
  background-color: #fff;
  padding: 65px 15px 20px;
  text-align: left;
  box-sizing: border-box;
}
.detail-title {
  font-size: 20px;
  font-weight: 700;
}
.detail-content {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.5;
  padding: 20px 0;
  text-align: justify;
}
.detail-grade {
  background: #f4f9fd;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  text-align: center;
}
.detail-grade-list {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.detail-grade-item {
  border-radius: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  padding: 5px 10px;
  background: #fff;
  cursor: pointer;
}
.detail-grade-item span {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.detail-grade-item img {
  overflow: hidden;
  filter: grayscale(100%);
}

.detail-grade-item .detail-grade-img {
  filter: grayscale(0);
}
</style>
