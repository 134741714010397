//登录 Login
export const Login = {
  accountPlaceholder: '请输入账号',
  passwordPlaceholder: '请输入密码',
  forgetPassword: '忘记密码',
  login: '登录',
  other: '其他登录方式',
  emailPlaceholder: '请输入邮箱',
  codePlaceholder: '请输入验证码',
  getCode: '获取验证码',
  emailCheck: '您还未填写邮箱！',
  newPassword: '输入新密码',
  repeatPassword: '重复新密码',
  changePassword: '修改密码',
}

//首页Header
export const Header = {
  myTicket: '我的工单'
}

//子页面NavBar
export const NavBar = {
  loginOut: '退出登录',
  isLoginOut: '是否确认退出登录',
  confirm: '确定',
  cancel: '取消'
}

//首页Main
export const Main = {
  selfService: '自助工具',
  questionSubmission: '问题提交',
  normalQuestion: '常见问题',
  more: '更多',
  problem6: '登录问题',
  problem1: '账号问题',
  problem2: '充值问题',
  problem3: '活动问题',
  problem4: 'bug问题',
  problem5: '申诉问题',
  problem7: '投诉举报',
  problem8: '其他',
  setPassword: '修改密码',
  banSearch: '封禁查询',
  rechargeSearch: '充值金额查询',
  couponSearch: '优惠券查询',
  article: '文章'
}
//我的工单
export const MyTicket = {
  title: '我的工单',
  rechargeType: '充值问题类型',
  paySuccessTime: '支付成功时间',
  payAmount: '付款金额',
  FBLink: 'FB活动链接',
  occurrenceTime: '发生时间',
  issueType: '申诉问题类型',
  complaintType: '投诉举报类型',
  characterName: '被举报人角色名',
  serverName: '被举报人区服名',
  characterID: '被举报人角色ID',
  ImageDetails: '图片详情',
  VideoDetails: '视频详情',
  noUseVideo: '您的浏览器不支持此视频格式。',
  processed: '已处理',
  processing: '处理中',
  closed: '已关闭',
  customerServiceResponse: '客服回复',
  all: '全部',
  abnormalRecharge: '充值异常',
  itemsNotReceived: '道具未到账',
  RefundIssue: '退款问题',
  BlockIssue: '封号问题',
  MuteIssue: '禁言问题',
  OtherIssues: '其他问题',
  IllegalSpeech: '违规言论',
  IllegalCharacterName: '违规角色名',
  OtherViolations: '其他违规',
  noReply: '暂无客服回复',
  replyTicket: '工单回复',
  evaluate: '评价',
}

//账号封禁查询closureSearch
export const closureSearch = {
  title: '账号封禁查询',
  GameName: '游戏名称',
  BanType: "封禁类型",
  BanReason: "封禁原因",
  BanTime: "封禁时间",
  UnblockOrder: "解封订单",
  UnblockOrderPendingPayment: '存在待支付解封订单',
  None: '无',
  GoToUnblock: '去解封',
  GoToAppeal: '去申诉',
  noData:'暂无封禁数据'
}

//充值金额查询
export const rechargeSearch = {
  title: '充值金额查询',
  rechargeDate: '充值时间',
  chooseStartDate: '开始时间',
  chooseEndDate: '结束时间',
  gameName: '游戏名',
  chooseGame: '请选择游戏',
  search: '查询',
  result: '查询结果',
  rechargeNum: '充值订单数量：',
  rechargeAmt: '充值订单总额：',
  startDateplaceholder: '请选择开始时间',
  endDateplaceholder: '请选择结束时间',
}

//优惠券查询
export const couponSearch = {
  title: '优惠券查询',
  couponName: '优惠券名称',
  couponType: '优惠券类型',
  couponDate: '优惠券过期时间',
  deliver1: '满{money} USD 送 {gift}Coin',
  deliver2: '满{money} USD 减 {gift}%',
  deliver3: '满{money} USD 减 {gift}',
  deliverVn1: 'Đủ {money} Tặng {gift}Coin',
  deliverVn2: 'Đủ {money} Tặng {gift}%',
  deliverVn3: 'Đủ {money} Giảm {gift}',
}

//常见问题详情detail
export const detail = {
  title: '问题详情',
  isAppraise: '这个回答有用吗？',
  appraise: '有用',
  noAppraise: '没用',
}

//活动文章detail
export const articleDetail = {
  title: '活动文章详情',
}

//问题提交problem
export const problem = {
  rechargeMethod: '充值方式',
  rechargeMethodPlaceholder: '请选择充值方式',
  applePurchase: '苹果内购',
  googlePurchase: '谷歌内购',
  WebPurchase: '网页充值',
  rechargeType: "充值问题类型",
  rechargeTypePlaceholder: '请选择充值问题类型',
  abnormalRecharge: '充值异常',
  itemsNotReceived: '道具未到账',
  cardRecharge: '卡充异常',
  cardNum: '卡号',
  cardNumPlaceholder: '请输入卡号',
  paySuccessTime: '支付成功时间',
  paySuccessTimePlaceholder: '请选择支付成功时间',
  payAmount: '付款金额',
  payAmountPlaceholder: '请输入付款金额',
  LoginType: '登录问题类型',
  LoginTypePlaceholder: '请选择登录问题类型',
  AccountProblem: '账号登录问题',
  selectServerProblem: '选择区服异常',
  roleLoginProblem: '角色登录问题',
  loginMethod: '登录方式',
  loginMethodPlaceholder: '请选择登录方式',
  emailLogin: '邮箱登录',
  FbLogin: 'FB登录',
  googleLogin: '谷歌登录',
  guestLogin: '游客登录',
  emailPlaceholder: '请输入邮箱',
  fbLink: 'FB活动链接',
  fbLinkPlaceholder: '请输入FB活动链接',
  bugOccur: '发生时间',
  bugOccurPlaceholder: '请选择发生时间',
  gameVersion: '游戏版本',
  gameVersionExample: '例如：1.0.318(点击手机设置-应用管理)',
  phoneSystemVersion: '手机系统版本号',
  phoneSystemVersionExample: '例如：安卓11',
  mobileDevice: '手机设备型号',
  mobileDeviceExample: '例如：Huawei p40',
  deviceInfo: '设备信息',
  deviceInfoExample: '例如：8+256G',
  network: '使用网络',
  networkExample: '例如：5G网络',
  appealType: '申诉问题类型',
  appealTypePlaceholder: '请选择申诉问题类型',
  blockIssue: '封号问题',
  muteIssue: '禁言问题',
  otherIssues: '其他问题',
  complaintsAndReports: '投诉举报',
  complaintsAndReportsPlaceholder: '请选择投诉举报类型',
  IllegalSpeech: '违规言论',
  IllegalCharacterName: '违规角色名',
  ReportCheating: '刷外挂举报',
  OtherViolations: '其他违规',
  characterName: '被举报人角色名',
  characterNamePlaceholder: '请输入被举报人角色名',
  serverName: '被举报人区服名',
  serverNamePlaceholder: '请输入被举报人区服名',
  characterID: '被举报人角色ID',
  characterIDPlaceholder: '请输入被举报人角色ID',
  chooseGame: '选择游戏',
  chooseGameplaceholder: '请选择游戏',
  chooseServer: '选择服务器',
  chooseServerplaceholder: '请选择服务器',
  chooseRole: '选择角色',
  chooseRoleplaceholder: '请选择角色',
  problemStyle: '问题类型',
  problemStyleplaceholder: '请选择问题类型',
  decPlaceholder: '请选择问题描述',
  contactPlaceholder: '请输入您的Zalo号码',
  problemName: '请输入问题名',
  decProblem: '描述问题',
  uploadImg: '上传图片',
  confirmUpload: '确认上传',
  confirmUploadDec: '只能上传jpg/png格式的文件，不能超过20MB',
  uploadVideo: '上传视频文件',
  confirmUploadVideoDec: '只能上传mp4文件（视频文件）',
  confirmUploadVideoDec2: '注意：某些Android模拟器（LDPlayer…）的视频录制功能可能会出现上传时不显示视频内容的错误，请使用移动设备上的屏幕录制模式确保显示。',
  contact: 'Zalo',
  confirm: '提交信息',
  maxChooseImg: '当前最多只能选择3个文件',
  onlyJpg: '只能上传jpg/png格式的图像！',
  maxSize: '文件上传不能超过20MB！',
  uploadVideoMsg: '请上传视频文件！',
  uploadSuccess: '上传成功',
  uploadFail: '上传失败,',
  onlyOneFile: '当前最多只能选择一个文件',
  atLeastOneFile: '至少选择一张图片',
}


//评价
export const starDialog = {
  title: '评价',
  satisfaction: '满意度',
  other: '其他意见',
  Reset: '重置',
  satisfaction1: '非常差',
  satisfaction2: '差',
  satisfaction3: '正常',
  satisfaction4: '满意',
  satisfaction5: '非常满意',
  gradePlaceholder: '请选择星级评价',
  commentPlaceholder: '请输入评价',
}

//工单回复
export const userReplyDialog = {
  title: '工单回复',
  decProblem: '描述问题',
  uploadImg: '上传图片',
  confirmUpload: '确认上传',
  confirmUploadDec: '只能上传jpg/png格式的文件，不能超过20MB',
  uploadVideo: '上传视频文件',
  confirmUploadVideoDec: '只能上传mp4文件（视频文件）',
  confirm: '确认',
  decPlaceholder: '请选择问题描述',
  maxChooseImg: '当前最多只能选择3个文件',
  onlyJpg: '只能上传jpg/png格式的图像！',
  maxSize: '文件上传不能超过20MB！',
  uploadVideoMsg: '请上传视频文件！',
  uploadSuccess: '上传成功',
  uploadFail: '上传失败,',
  onlyOneFile: '当前最多只能选择一个文件',
}