<template>
  <div class="coupon">
    <NavBar :title="$t('couponSearch.title')" routeUrl="/" />
    <div class="coupon-body">
      <div class="coupon-item" v-for="item in coupon" :key="item.id">
        <div>
          <span class="coupon-left">{{ $t("couponSearch.couponName") }}</span
          >：
          {{ item.title }}
        </div>
        <div v-if="version == 'V2'">
          <span class="coupon-left">{{ $t("couponSearch.couponType") }}</span
          >：
          {{
            $t(`couponSearch.deliverVn${item.type}`, {
              money: item.order_coin,
              gift: Number(item.type) === 1 ? item.give_coin : item.reduce_amt,
            })
          }}
        </div>
        <div v-if="version == 'V3'">
          <span class="coupon-left">{{ $t("couponSearch.couponType") }}</span
          >：
          {{
            $t(`couponSearch.deliver${item.type}`, {
              money: item.order_coin,
              gift: Number(item.type)  === 2 ? item.rebate : item.give_coin,
            })
          }}
        </div>
        <div>
          <span class="coupon-left">{{ $t("couponSearch.couponDate") }}</span
          >： {{ item.valid_start_time }} - {{ item.valid_end_time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "question",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      token: localStorage.getItem("token"),
      coupon: [],
      version: "V2",
      type: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .post("/api/v2/searchCoupon   ", { token: this.token })
        .then((res) => {
          if (res.code === 200) {
            this.coupon = res.data.data;
            this.version = res.data.version;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.coupon-body {
  margin-top: 45px;
}
.coupon-item {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  text-align: left;
  color: gray;
  font-size: 14px;
}
.coupon-item div {
  margin-bottom: 10px;
}
.go-coupon {
  margin-left: 20px;
  color: #1188ff;
  cursor: pointer;
}
.coupon-left {
  font-weight: bold;
  color: black;
}
</style>
