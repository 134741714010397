<template>
  <div class="" v-if="true">
    <div
      class="kefu-img"
      :style="{ right: $isMobile ? '20px' : '35%' }"
      @click="handleRouter()"
    >
      <svg
        width="24px"
        style="display: inline-block"
        height="24px"
        viewBox="0 0 59 59"
        color="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="clip0_106_902">
            <rect width="58.7755" height="58.7755"></rect>
          </clipPath>
        </defs>
        <g clip-path="url(#clip0_106_902)">
          <path
            d="M49.5674 0.979614H9.4041C4.89798 0.979614 0.979614 4.70206 0.979614 9.4041V33.8939C0.979614 38.4 4.70206 42.3184 9.4041 42.3184H14.3021L9.01227 56.6204C8.81635 57.0123 9.01227 57.4041 9.4041 57.7959C9.60002 57.9919 9.79594 57.9919 9.99186 57.9919C10.1878 57.9919 10.3837 57.9919 10.5796 57.7959L29.9755 42.3184H49.7633C54.2694 42.3184 58.1878 38.5959 58.1878 33.8939V9.4041C57.7959 4.70206 54.0735 0.979614 49.5674 0.979614ZM16.6531 24.4898C14.498 24.4898 12.7347 22.7266 12.7347 20.5714C12.7347 18.4163 14.498 16.6531 16.6531 16.6531C18.8082 16.6531 20.5714 18.4163 20.5714 20.5714C20.5714 22.7266 18.8082 24.4898 16.6531 24.4898ZM28.4082 24.4898C26.2531 24.4898 24.4898 22.7266 24.4898 20.5714C24.4898 18.4163 26.2531 16.6531 28.4082 16.6531C30.5633 16.6531 32.3266 18.4163 32.3266 20.5714C32.3266 22.7266 30.5633 24.4898 28.4082 24.4898ZM40.1633 24.4898C38.0082 24.4898 36.2449 22.7266 36.2449 20.5714C36.2449 18.4163 38.0082 16.6531 40.1633 16.6531C42.3184 16.6531 44.0817 18.4163 44.0817 20.5714C44.0817 22.7266 42.3184 24.4898 40.1633 24.4898Z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      :width="$isMobile ? '95%' : '500px'"
    >
      <iframe
        style="width: 100%"
        :style="{ height: $isMobile ? '500px' : '600px' }"
        src="https://connect.tencentcloud.com/web/im/chat/?webAppId=f04c428f26330ffbb8c48ec905c3b398&isFromUserGuide=true"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleRouter() {
      // window.open(
      //   "https://tccc.qcloud.com/web/im/chat/?webAppId=2e274423e5c36abfde08e145a18ef9b3",
      //   "_blank"
      // );
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
.kefu-img {
  position: fixed;
  right: 35%;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  bottom: 20px;
  background: rgb(0, 110, 255);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px;
  cursor: pointer;
}
/deep/.el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  border-radius: 30px;
  padding: 0px 0px 0;
}
</style>
