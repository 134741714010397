<template>
  <div class="">
    <el-dialog
      :title="$t('userReplyDialog.title')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :width="$isMobile ? '90%' : '500px'"
      center
    >
      <div class="dialogContent">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
          label-width="150px"
          class="demo-ruleForm login-container"
        >
          <el-form-item :label="$t('userReplyDialog.decProblem')" prop="reply">
            <el-input type="textarea" v-model="ruleForm.reply"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userReplyDialog.uploadImg')" prop="imgUrl">
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :before-upload="handlebeforeUpload"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              accept="image/jpeg, image/png"
              :file-list="fileImgList"
            >
              <i class="el-icon-plus"></i>
              <!-- <el-button size="small" type="primary">
              {{ $t("problem.confirmUpload") }}
            </el-button> -->
              <div slot="tip" class="el-upload__tip">
                {{ $t("problem.confirmUploadDec") }}
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            :label="$t('userReplyDialog.uploadVideo')"
            prop="videoUrl"
          >
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              :before-upload="beforeUpload"
              :on-exceed="onExceed"
              :on-success="onSuccess"
              :on-error="onError"
              :on-preview="handleVideoPreview"
              :on-remove="handleRemove"
              accept="video/*"
              :limit="1"
              :file-list="fileVideoList"
              list-type="picture-card"
            >
              <!-- <el-button slot="trigger" size="small" type="primary">
              {{ $t("problem.confirmUpload") }}
            </el-button> -->
              <i class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">
                <div>{{ $t("problem.confirmUploadVideoDec") }}</div>
                <div style="line-height: 15px; color: red">
                  {{ $t("problem.confirmUploadVideoDec2") }}
                </div>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClick" type="primary">{{
          $t("userReplyDialog.confirm")
        }}</el-button>
        <!-- <el-button @click="dialogVisible = false">关 闭</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      :width="$isMobile ? '90%' : '500px'"
      center
      :visible.sync="imgDialogVisible"
      :before-close="imgVideoHandleClose"
    >
      <img width="50%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      :width="$isMobile ? '90%' : '500px'"
      center
      :visible.sync="videoDialogVideoVisible"
      :before-close="imgVideoHandleClose"
    >
      <!-- <img width="100%" :src="dialogImageUrl" alt="" /> -->
      <video :src="videoUrl" controls width="300" height="240"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      ticket_id: "",
      token: localStorage.getItem("token"),
      ruleForm: {
        reply: "",
        imgUrl: [],
        videoUrl: "",
      },
      rules: {
        reply: [
          {
            required: true,
            message: this.$t("userReplyDialog.decPlaceholder"),
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      fileImgList: [],
      videoUrl: "",
      fileVideoList: [],
      dialogImageUrl: "",
      imgDialogVisible:false,
      videoDialogVideoVisible:false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.videoUrl = "";
      this.fileImgList = [];
      this.fileVideoList = [];
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleReset();
    },
    handleClick() {
      var _this = this;
      this.ruleForm.imgUrl = this.fileList;
      this.ruleForm.videoUrl = this.videoUrl;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ticket_id: this.ticket_id,
            ..._this.ruleForm,
            token: _this.token,
          };
          this.$http.post("/api/v2/ticketReply", params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.handleReset();
              this.$parent.getData();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess(res, file) {
      if (res.code === 200) {
        this.fileList.push(res.data.url);
      }
    },
    handleRemove(file, fileList) {
      let list = [];
      fileList.forEach((item) => {
        list.push(item.response.data.url);
      });
      this.fileList = list;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("userReplyDialog.maxChooseImg"));
    },
    handlebeforeUpload(files, fileList) {
      return new Promise((resolve, reject) => {
        const suffix =
          files.type === "image/jpg" ||
          files.type === "image/png" ||
          files.type === "image/jpeg";
        const isLt1M = files.size / 1024 / 1024 < 20;
        if (!suffix) {
          this.$message.error(this.$t("userReplyDialog.onlyJpg"));
          return reject(false);
        }
        if (!isLt1M) {
          this.$message.error(this.$t("userReplyDialog.maxSize"));
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
        return resolve(true);
      });
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      if (file.type.startsWith("video/")) {
        // 限制文件大小为50MB
        const MAX_SIZE = 20 * 1024 * 1024;
        if (file.size > MAX_SIZE) {
          this.$message.error(this.$t("userReplyDialog.maxSize"));
          return false;
        }
        // 文件大小符合要求，可以继续上传
        return true;
      } else {
        this.$message.error(this.$t("userReplyDialog.uploadVideoMsg"));
        return false;
      }
    },
    onSuccess(res, file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      // file.url = videoDefCover; //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        console.log(video.clientWidth, video.clientHeight);
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
      this.$message.success(this.$t("problem.uploadSuccess"));
      if (res.code === 200) {
        this.videoUrl = res.data.url;
      }
      // 处理上传成功后的逻辑，例如更新UI或者处理其他数据
    },
    onError(res, file, fileList) {
      this.$message.error(this.$t("userReplyDialog.uploadFail") + res.msg);
      // 处理上传失败后的逻辑
    },
    onExceed(files, fileList) {
      this.$message.warning(this.$t("userReplyDialog.onlyOneFile"));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    handleVideoPreview(file) {
      this.$nextTick(() => {
        this.videoDialogVideoVisible = true;
      });
    },
    imgVideoHandleClose() {
      this.imgDialogVisible = false;
      this.videoDialogVideoVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .avatar {
  width: 80px;
  height: 80px;
}
 /* 自定义进度条样式 */
 /deep/.el-upload-list--picture-card .el-progress {
  width: 80px; /* 设置进度条的高度 */
}
/deep/ .el-progress-circle {
  width: 80px !important;
  height: 80px !important;
}
/deep/.el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}
/deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
}
.dialogContent {
  height: 100%;
  overflow: auto;
}
.box-card {
  margin-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .dialogContent {
    height: 100%;
  }
}
</style>
