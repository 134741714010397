import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main";
import Login from "../views/Login";
import Question from "../views/Question";
import Detail from "../views/Question/components/detail";
import Article from "../views/Article";
import ArticleDetail from "../views/Article/components/articleDetail.vue";
import Problem from "../views/Problem";
import MyTicket from "../views/MyTicket";
import TicketInfo from "../views/MyTicket/components/ticketInfo.vue";
import Account from "../views/Problem/components/account.vue";
import RoleSearch from "../views/Problem/components/roleSearch.vue";
import ClosureSearch from "../views/Problem/components/closureSearch.vue";
import RechargeSearch from "../views/Problem/components/rechargeSearch.vue";
import CouponSearch from "../views/Problem/components/couponSearch.vue";
import SetPassword from "../views/Problem/components/setPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: {
      title: 'Main'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Login'
    },
  },
  {
    path: "/question",
    name: "Question",
    component: Question,
    meta: {
      title: 'Question'
    },
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
    meta: {
      title: 'Detail'
    },
  },
  {
    path: "/problem",
    name: "Problem",
    component: Problem,
    meta: {
      title: 'problem'
    },
  },
  {
    path: "/article",
    name: "Article",
    component: Article,
    meta: {
      title: 'article'
    },
  },
  {
    path: "/articleDetail",
    name: "ArticleDetail",
    component: ArticleDetail,
    meta: {
      title: 'ArticleDetail'
    },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      title: 'Account'
    },
  },
  {
    path: "/roleSearch",
    name: "RoleSearch",
    component: RoleSearch,
    meta: {
      title: 'RoleSearch'
    },
  },
  {
    path: "/closureSearch",
    name: "ClosureSearch",
    component: ClosureSearch,
    meta: {
      title: 'ClosureSearch'
    },
  },
  {
    path: "/rechargeSearch",
    name: "RechargeSearch",
    component: RechargeSearch,
    meta: {
      title: 'RechargeSearch'
    },
  },
  {
    path: "/couponSearch",
    name: "CouponSearch",
    component: CouponSearch,
    meta: {
      title: 'CouponSearch'
    },
  },
  {
    path: "/setPassword",
    name: "SetPassword",
    component: SetPassword,
    meta: {
      title: 'SetPassword'
    },
  },
  {
    path: "/myTicket",
    name: "MyTicket",
    component: MyTicket,
    meta: {
      title: 'MyTicket'
    },
  },
  {
    path: "/ticketInfo",
    name: "TicketInfo",
    component: TicketInfo,
    meta: {
      title: 'TicketInfo'
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.meta && to.meta.title) {
  //   document.title = to.meta.title // 设置页面标题
  // }
  if (to.path == "/login" || to.fullPath.includes("/#/login")) {
    localStorage.removeItem("token");
  }
  let user = localStorage.getItem("token");
  if (!user && to.path != "/login" && to.path != "/setPassword") {
    if (to.fullPath.includes("/#/")) {
      let urlString = to.fullPath
      urlString = urlString.replace("/#/", "/");
      if (urlString.includes("/login")) {
        localStorage.removeItem("token");
      }
      window.location.href = window.location.origin + urlString
    } else {
      next({ path: "/login" });
    }
  } else {
    next();
  }
})

export default router;
