<template>
  <div class="recharge">
    <NavBar :title="$t('rechargeSearch.title')" routeUrl="/" />
    <div class="recharge-body">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        class="demo-ruleForm login-container"
      >
        <el-form-item :label="$t('rechargeSearch.rechargeDate')" required>
          <el-col :span="11">
            <el-form-item prop="start_date">
              <el-date-picker
                v-model="ruleForm.start_date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="{ ...editStartOptions }"
                :placeholder="$t('rechargeSearch.chooseStartDate')"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">-</el-col>
          <el-col :span="11">
            <el-form-item prop="end_date">
              <el-date-picker
                v-model="ruleForm.end_date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="{ ...editStopOptions }"
                :placeholder="$t('rechargeSearch.chooseEndDate')"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('rechargeSearch.gameName')" prop="game_id">
          <el-select
            v-model="ruleForm.game_id"
            style="width: 100%"
            clearable 
            :placeholder="$t('rechargeSearch.chooseGame')"
          >
            <el-option
              :label="item.game_name"
              :value="item.game_id"
              v-for="item in servers_roles"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%"
            @click="submitForm('ruleForm')"
            >{{ $t("rechargeSearch.search") }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-dialog
        :width="$isMobile ? '90%' : '500px'"
        center
        :title="$t('rechargeSearch.result')"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <div>{{ $t("rechargeSearch.rechargeNum") }} {{ recharge.num }}</div>
        <div>
          {{ $t("rechargeSearch.rechargeAmt") }} 
          {{ recharge.amt }}
          {{ recharge.coin }}
        </div>
      </el-dialog>
      <!-- <div v-if="recharge.num" class="recharge-style">
        <div>充值订单数量：{{ recharge.num }}</div>
        <div>充值订单总额：{{ recharge.amt }}{{ recharge.coin }}</div>
      </div>
      <div v-else style="text-align: center">
        <img
          src="../../../assets/img/nodata.png"
          alt=""
          class="recharge-style"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "question",
  mixins: [],
  components: { NavBar },
  props: {},
  data() {
    return {
      token: localStorage.getItem("token"),
      recharge: {},
      servers_roles: [],
      dialogVisible: false,
      ruleForm: {
        game_id: "",
        start_date: "",
        end_date: "",
      },
      rules: {
        start_date: [
          {
            type: "string",
            required: true,
            message: this.$t("rechargeSearch.startDateplaceholder"),
            trigger: "change",
          },
        ],
        end_date: [
          {
            type: "string",
            required: true,
            message: this.$t("rechargeSearch.endDateplaceholder"),
            trigger: "change",
          },
        ],
      },
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.ruleForm.end_date) {
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.ruleForm.end_date);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          //开始时间可以和结束时间相同增加
          let start_date = new Date(this.ruleForm.start_date);
          start_date.setDate(start_date.getDate() - 1);
          return (
            time.getTime() < start_date ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
    };
  },
  computed: {},
  watch: {
    "ruleForm.start_date": {
      handler(newVal) {
        if (
          newVal &&
          this.ruleForm.end_date &&
          new Date(newVal).getTime() >
            new Date(this.ruleForm.end_date).getTime()
        ) {
          this.ruleForm.start_date = this.ruleForm.end_date;
        }
      },
    },
    "ruleForm.end_date": {
      handler(newVal) {
        if (
          newVal &&
          this.ruleForm.start_date &&
          new Date(newVal).getTime() <
            new Date(this.ruleForm.start_date).getTime()
        ) {
          // this.ruleForm.start_date = newVal;
          this.ruleForm.end_date = this.ruleForm.start_date;
        }
      },
    },
  },
  created() {},
  mounted() {
    this.getServerAndRoleInfo();
  },
  methods: {
    //获取游戏区服角色接口
    getServerAndRoleInfo() {
      let params = {
        token: this.token,
      };
      this.$http.post("/api/v2/gameList", params).then((res) => {
        if (res.code == 200) {
          this.servers_roles = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            token: this.token,
            game_id: this.ruleForm.game_id,
            start_date: this.ruleForm.start_date,
            end_date: this.ruleForm.end_date,
          };
          this.$http.post("/api/v2/searchRecharge", params).then((res) => {
            if (res.code === 200) {
              console.log(res);
              this.recharge = res.data;
              this.dialogVisible = true;
            }else{
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
.recharge-body {
  margin-top: 45px;
  background-color: #fff;
  padding: 20px 15px;
  text-align: left;
  box-sizing: border-box;
}
</style>
